<!--
File: TreatmentTypeEditForm.vue
Description: form for adding/editing a single treatment.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-40">
          <md-field :class="[{ 'md-valid': !errors.has('key') }, { 'md-error': errors.has('key') }]">
            <label>{{ $t('treatment.treatment_key') }}</label>
            <md-input v-model="key" type="text" data-vv-name="key" v-validate="modelValidations.key" required />
          </md-field>

          <md-field :class="[
            { 'md-valid': !errors.has('treatment_type_description') }, { 'md-error': errors.has('treatment_type_description') }
          ]">
            <label>{{ $t('treatment.treatment_description') }}</label>
            <md-input v-model="treatment_type_description" type="text" data-vv-name="treatment_type_description"
              v-validate="modelValidations.treatment_type_description" required />
          </md-field>

          <md-field :class="[{ 'md-valid': !errors.has('key_en') }, { 'md-error': errors.has('key_en') }]">
            <label>{{ $t('treatment.treatment_key_en') }}</label>
            <md-input v-model="key_en" type="text" data-vv-name="key_en" v-validate="modelValidations.key_en" required />
          </md-field>

          <md-field :class="[
            { 'md-valid': !errors.has('treatment_type_description_en') }, { 'md-error': errors.has('treatment_type_description_en') }
          ]">
            <label>{{ $t('treatment.treatment_description_en') }}</label>
            <md-input v-model="treatment_type_description_en" type="text" data-vv-name="treatment_type_description_en"
              v-validate="modelValidations.treatment_type_description_en" required />
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <WorkCategoriesDropdown :label="$t('translate.category')" v-model="fk_work_category"
            :initial_value="fk_work_category" data-vv-name="fk_work_category" :is_required="true" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <UnitsDropdown :class="[{ 'md-valid': !errors.has('fk_unit') }, { 'md-error': errors.has('fk_unit') }]"
            :label="$t('label.units')" v-model="fk_unit" :initial_value="fk_unit" data-vv-name="fk_unit"
            v-validate="modelValidations.fk_unit" :is_required="true" />
        </div>
        <div class="md-layout-item md-small-size-20 md-size-15">
          <BaseDropdown :label="$t('label.expected_outcome')" v-model="expected_outcome" :items="surfaceTypes"
            :displayField="'description'" :valueField="'description'" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <label :class="[{ 'md-valid': !errors.has('color') }, { 'md-error': errors.has('color') }]">{{
            $t('treatment.select_color') }}
          </label>
          <VSwatches v-model="color" :swatches="swatches" shapes="circles" inline show-checkbox show-border
            v-validate="modelValidations.color" data-vv-name="color" />
        </div>
        <ul>
          <li v-for="error in errors.all()">{{ error }}</li>
        </ul>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import Swal from 'sweetalert2'
  import UnitsDropdown from '../Dropdowns/UnitsDropdown.vue'
  import WorkCategoriesDropdown from '../Dropdowns/WorkCategoriesDropdown.vue'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
  import VSwatches from 'vue-swatches'
  import 'vue-swatches/dist/vue-swatches.css'
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'treatment-type-edit-form',
    data() {
      return {
        fk_work_category: null,
        treatment_type_id: null,
        key: null,
        treatment_type_description: null,
        key_en: null,
        treatment_type_description_en: null,
        fk_unit: null,
        expected_outcome: null,
        expected_outcome_en: null,
        color: null,
        swatches: ['red', 'orange', 'yellow', 'green', 'cyan', 'blue', 'magenta', 'black', ''],

        modelValidations: {
          key: { required: true, min: 2 },
          treatment_type_description: { required: true, min: 3 },
          key_en: { required: true, min: 2 },
          treatment_type_description_en: { required: true, min: 3 },
          fk_unit: { required: true, numeric: true, min_value: 1 },
          color: { required: true },
        }
      }
    },
    props: {
      oper: String
    },
    components: {
      UnitsDropdown,
      WorkCategoriesDropdown,
      BaseDropdown,
      VSwatches
    },
    created() {
      const { treatment_type_id = null } = this.$route.params
      this.treatment_type_id = treatment_type_id
      this.$store.dispatch('LOAD_SURFACE_TYPES')
      if (this.oper === 'upd' && treatment_type_id) {
        this.$store.dispatch('LOAD_TREATMENT_TYPE_BY_ID', treatment_type_id).then((res) => {
          const treatmentType = Array.isArray(res) ? res[0] : res
          console.log('treatmentType', treatmentType)
          this.fk_work_category = treatmentType.fk_work_category
          this.key_en = treatmentType.key_en
          this.treatment_type_description_en = treatmentType.treatment_type_description_en
          this.key = treatmentType.key
          this.treatment_type_description = treatmentType.treatment_type_description
          this.fk_unit = treatmentType.fk_unit
          this.expected_outcome = treatmentType.expected_outcome
          //this.expected_outcome_en = treatmentType.expected_outcome_en
          this.color = treatmentType.color
        })
      }
    },
    methods: {
      onClose() {
        history.back()
        history.replaceState({}, "", "/treatments/types")
      },
      async validate() {
        this.$validator.validateAll().then((isValid) => {
          const alert = {
            icon: 'success',
            text: this.$t(`treatment.treatment_description`) + this.$t(`label.was_saved`, {
              name_ru: this.treatment_type_description,
              name_en: this.treatment_type_description_en
            }),
            footer: ''
          }
          if (isValid) {
            const treatmentType = {
              fk_work_category: this.fk_work_category,
              key_en: this.key_en,
              treatment_type_description_en: this.treatment_type_description_en,
              key: this.key,
              treatment_type_description: this.treatment_type_description,
              fk_unit: this.fk_unit,
              expected_outcome: this.expected_outcome,
              expected_outcome_en: this.expected_outcome,
              color: this.color
            }

            const reqData = this.oper === 'add' ? treatmentType : { id: this.treatment_type_id, treatment_type: treatmentType }
            this.$store
              .dispatch(`${this.oper.toUpperCase()}_TREATMENT_TYPE`, reqData).then(
                () => {
                  Swal.fire(alert).then(() => {
                    this.$nextTick(() => this.$validator.reset())
                  })
                },
                (err) => {
                  alert.icon = 'error'
                  alert.text = this.$t(`treatment.treatment_description`) + this.$t(`label.was_not_saved`, {
                    name_ru: this.treatment_type_description,
                    name_en: this.treatment_type_description_en
                  })
                  alert.footer = err
                  Swal.fire(alert)
                }
              )
          } else {
            alert.footer = this.$t('messages.errors_count', { count: this.errors.items.length })
            return
          }
          Swal.fire(alert)
        })
        // To return back to the list
        this.onClose()
      }
    },
    watch: {
    },
    computed: {
      ...mapState({
        //surfaceTypes: (state) => state.RefData.surface_types,
      }),
      ...mapGetters(['surfaceTypesByLang']),
      surfaceTypes() {
        return this.surfaceTypesByLang('en')
      },
      saveBtnDisabled() {
        return this.errors.items.length > 0
      }
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
  